<template>
  <PowerupSelectionUi
    v-if="!powerupSelected"
    :onPowerUpClicked="onPowerUpClicked"
    :powerups="powerups"
    :progressVal="matchStartProgress"
  />
  <div v-show="powerupSelected" v-bind:style="backgroundImage" class="tut-main">
    <ScoreUi :room="room" />

    <PowerupsUi
      v-if="playerPowerups.length"
      :playerPowerups="playerPowerups"
      :opponentPowerups="botPowerups"
      @on-powerup-use="handlePowerupUse"
    />

    <div :style="{ height: '52%' }">
      <!-- main canvas container -->
      <div id="canvas-container">
        <div v-show="showPotentialMoves" class="grid-container position-absolute">
          <div
            v-for="cellNumber in gridCells"
            :key="cellNumber"
            :id="cellNumber"
            class="grid-item"
          ></div>
        </div>
        <div id="canvas-overlay"></div>
      </div>
      <TimerUi :turn="turn" :timerColor="timerColor" :progressVal="progressVal" />
    </div>
    <NavigationUi />

    <div
      v-if="showInstruction"
      class="bottom-0 d-flex justify-content-end position-absolute w-100 show-instruction-div"
    >
      <div
          class="w-100 mt-4 right-chat-box"
        >
          <p class="fs-6 mx-0 my-0" style="color: #f25b60;">lionel:</p>
          <p class="chat-text" style="color: black;">its your turn! quick, match as many jewels as you can!</p>
        </div>
      <div class="h-100">
        <img :src="firstGameScreenElements['lion2']" alt="" class="h-100" />
      </div>
    </div>

    <div
      v-if="showWinner"
      class="h-100 op opacity-25 position-absolute w-100 z-1"
      style="background-color: black"
    ></div>

    <UserWinsUi v-if="showWinner" />
  </div>
</template>

<script>
  import { MegamatchPixi } from '../../../handlers/MegamatchPixi';

  import NavigationUi from '../../NavigationUi';
  import PowerupsUi from '../../PowerupsUi';
  import ScoreUi from '../../ScoreUi';
  import TimerUi from '../../TimerUi';
  import UserWinsUi from '../UserWinsUi';
  import PowerupSelectionUi from '../../PowerupSelectionUi';

  import {
    Events,
    PlayerTurn,
    ScoreConfig,
    POWER_UPS,
    POWERUPS_STATUS,
  } from '../../../enum/enum';
  import { Config } from '../../../game/Config';
  import FirstGameData from '../../../game/FirstGameData';
  import { Game } from '../../../game/Game';

  import { staticImages, dynamicBg } from '../../../constants/imageUrlPath';
  import { getRandomNumber } from '../../../common/funtions';

  /** @type MegamatchPixi */
  let pixiObj = undefined;

  /** @type Game */
  let gameObject = undefined;

  const playerPowerupsArray = [
    'doublePoints',
    'freeze',
    'reShuffle',
    'swapTwo',
    'burstFour',
    'potentialMoves',
  ];
  const botPowerupsArray = ['doublePoints', 'reShuffle', 'burstFour'];

  export default {
    name: 'FirstGameUi',
    components: {
      ScoreUi,
      PowerupsUi,
      TimerUi,
      NavigationUi,
      UserWinsUi,
      PowerupSelectionUi,
    },
    data() {
      return {
        powerupSelected: false,
        matchStartProgress: 100,
        matchStartTimer: 10000,

        /**User selected Powerups
         * @type Array
         */
        powerups: [],

        showInstruction: true,
        showPotentialMoves: true,
        turn: 'Your Turn',
        turnCount: 1,
        totalTurns: 16,
        timeLeft: 15000,
        progressVal: 100,
        timerColor: 'bg-info',
        showWinner: false,
        firstGameScreenElements: {
          textBox11: staticImages.textBox11,
          lion2: staticImages.lion2,
        },
        room: [
          {
            score: 0,
            turnNo: 0,
            avatar: 'armadillo',
            userName: 'You',
          },
          {
            score: 0,
            turnNo: 0,
            avatar: 'armadillo',
            userName: 'Brutus',
          },
        ],
        cellIds: [23, 24, 25, 26, 32],

        /* powerup states */
        /** @type Array<{name: string, status: boolean}> */
        playerPowerups: [],
        /** @type Array<{name: string, status: boolean}> */
        botPowerups: [],

        doublePointsActive: false,
        freezeActive: false,
        swapTwoActive: false,
        burstFourActive: false,
      };
    },

    computed: {
      gridCells() {
        const numCells = 49;
        return Array.from({ length: numCells }, (_, index) => index + 1);
      },

      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[this.$DYNAMIC_BG] || dynamicBg[0]})`,
          '--blue-player-score-div-background': `url(${staticImages['bluePlayerTurn']})`,
          '--red-player-score-div-background': `url(${staticImages['redPLayerTurn']})`,
          '--powerup-container-background': `url(${staticImages['powerupContainer']})`,
          '--main-canvas-border': `url(${staticImages['gameBoard']})`,
        };
      },
    },

    methods: {
      onWindowResize() {
        pixiObj.configInstace = new Config(true);
      },

      startPowerupSelectTimer() {
        clearInterval(this.powerupTimer);

        this.powerupTimer = setInterval(() => {
          if (this.matchStartTimer > 0) {
            this.matchStartTimer -= 100;
            this.matchStartProgress = (this.matchStartTimer / 10000) * 100;
          } else {
            clearInterval(this.powerupTimer);
            this.powerupSelected = true;
            if (this.powerups.length == 1) {
              let p1 = this.powerups[0];
              for (let i = 0; i < playerPowerupsArray.length; i++) {
                if (playerPowerupsArray[i] != p1) {
                  this.powerups.push(playerPowerupsArray[i]);
                  break;
                }
              }
            } else if (this.powerups.length == 0) {
              let playerPowerupsIndex = getRandomNumber(
                0,
                playerPowerupsArray.length - 1,
              );
              this.powerups.push(playerPowerupsArray[playerPowerupsIndex]);
              this.powerups.push(
                playerPowerupsArray[
                  (playerPowerupsIndex + 1) % (playerPowerupsArray.length - 1)
                ],
              );
            }
            this.playerPowerups = this.powerups.map(name => ({
              name,
              status: POWERUPS_STATUS.NOT_USED,
            }));
            let botPowerupsIndex = getRandomNumber(0, botPowerupsArray.length - 1);
            this.botPowerups.push(botPowerupsArray[botPowerupsIndex]);
            this.botPowerups.push(
              botPowerupsArray[(botPowerupsIndex + 1) % (botPowerupsArray.length - 1)],
            );
            this.botPowerups = this.botPowerups.map(name => ({
              name,
              status: POWERUPS_STATUS.NOT_USED,
            }));
          }
        }, 100);
      },

      onPowerUpClicked(e) {
        const id = e.target.id;
        if (this.powerups.length < 2 && !this.powerups.includes(id)) {
          this.powerups.push(id);
          document.getElementById(id).style.opacity = 0.5;
        } else if (this.powerups.includes(id)) {
          const index = this.powerups.indexOf(id);
          this.powerups.splice(index, 1);
          document.getElementById(id).style.opacity = 1;
        }
      },

      async handleBotPlay() {
        let botPowerupName;
        if (this.turnCount == 6) {
          botPowerupName = this.botPowerups[0].name;
        }
        if (this.turnCount == 12) {
          botPowerupName = this.botPowerups[1].name;
        }
        if (![POWER_UPS.SWAP_TWO, POWER_UPS.BURST_FOUR].includes(botPowerupName)) {
          await gameObject.playPowerupAnimation(botPowerupName);
        }
        switch (botPowerupName) {
          case POWER_UPS.DOUBLE_POINTS: {
            this.doublePointsActive = true;
            this.botPowerups = this.botPowerups.map(pp => {
              if (Object.values(pp).includes('doublePoints')) {
                pp.status = POWERUPS_STATUS.USED;
              }
              return pp;
            });
            break;
          }
          case POWER_UPS.RESHUFFLE: {
            this.reshuffleBoard();
            this.botPowerups = this.botPowerups.map(pp => {
              if (Object.values(pp).includes('reShuffle')) {
                pp.status = POWERUPS_STATUS.USED;
              }
              return pp;
            });
            break;
          }
          case POWER_UPS.BURST_FOUR: {
            let tileRow = getRandomNumber(0, 5);
            let tileCol = getRandomNumber(0, 5);
            let tile1 = { row: tileRow, col: tileCol };
            let tile2 = { row: tileRow, col: tileCol + 1 };
            let tile3 = { row: tileRow + 1, col: tileCol };
            let tile4 = { row: tileRow + 1, col: tileCol + 1 };
            gameObject.destroyFour(tile1, tile2, tile3, tile4);
            this.botPowerups = this.botPowerups.map(pp => {
              if (Object.values(pp).includes('burstFour')) {
                pp.status = POWERUPS_STATUS.USED;
              }
              return pp;
            });
            return;
          }
          default:
            break;
        }
        if (botPowerupName) {
          setTimeout(() => {
            gameObject.potentialMoves();
          }, 3000);
        } else {
          gameObject.potentialMoves();
        }
      },

      async handlePowerupUse(powerupName) {
        if (
          (!gameObject.disabled && this.turn !== 'Your Turn') ||
          gameObject.disabled ||
          !powerupName ||
          this.showInstruction
        ) {
          return;
        }

        if (document.getElementById('canvas-overlay').style.display === 'block') {
          return;
        }

        if (![POWER_UPS.SWAP_TWO, POWER_UPS.BURST_FOUR].includes(powerupName)) {
          await gameObject.playPowerupAnimation(powerupName);
        }

        /* switch case for hanging edge case */
        switch (powerupName) {
          case POWER_UPS.DOUBLE_POINTS: {
            this.doublePointsActive = true;
            break;
          }
          case POWER_UPS.FREEZE: {
            this.freezeTimer();
            break;
          }
          case POWER_UPS.RESHUFFLE: {
            this.showPotentialMoves = false;
            this.reshuffleBoard();
            break;
          }
          case POWER_UPS.BURST_FOUR: {
            /* we are not allowing burst 4 powerup if user already selected swap any 2  */
            if (gameObject.swapTwoValidation) {
              return;
            }
            gameObject.destroyFourValidation = true;
            this.burstFourActive = true;
            break;
          }
          case POWER_UPS.POTENTIAL_MOVES: {
            /* we are not allowing potential moves powerups if user already selected burst 4 powerup */
            if (gameObject.destroyFourValidation) {
              return;
            }
            this.resetCellsTransparency();
            this.handlePotentialMoves();
            this.showPotentialMoves = true;
            this.makeCellsTransparent();
            break;
          }
          /* we are not allowing swap any 2 powerup if user already selected burst 4 powerup */
          case POWER_UPS.SWAP_TWO: {
            if (gameObject.destroyFourValidation) {
              return;
            }
            gameObject.swapTwoValidation = true;
            this.swapTwoActive = true;
            break;
          }
          default:
            break;
        }

        /* update powerup button state */
        this.playerPowerups = this.playerPowerups.map(pp => {
          if (Object.values(pp).includes(powerupName)) {
            /* setting selected status for this 3 powerups */
            if (
              [
                POWER_UPS.SWAP_TWO,
                POWER_UPS.BURST_FOUR,
                POWER_UPS.DOUBLE_POINTS,
              ].includes(powerupName)
            ) {
              pp.status = POWERUPS_STATUS.SELECTED;
            } else {
              pp.status = POWERUPS_STATUS.USED;
            }
          }
          return pp;
        });
      },

      freezeTimer() {
        this.freezeActive = true;
        setTimeout(() => {
          this.freezeActive = false;
        }, 15000);
      },

      async reshuffleBoard() {
        let shuffledBoard = null;
        const gameBoard = Array(7)
          .fill(0)
          /* gen random number from 0 to 4 */
          .map(() =>
            Array(7)
              .fill(0)
              .map(() => Math.floor(Math.random() * ScoreConfig.length)),
          );
        const tempGameBoard = new Game(gameBoard, []);
        shuffledBoard = tempGameBoard.get2dBoard();
        await new Promise(resolve => {
          setTimeout(() => {
            gameObject.updateBoard(shuffledBoard, FirstGameData.firstGameNextTiles);
            resolve();
          }, 700);
        });
      },

      handlePotentialMoves() {
        const coordinatePairs = gameObject.potentialMoves();
        const tile1 = {};
        const tile2 = {};
        tile1.row = coordinatePairs.tile1.field.row;
        tile1.col = coordinatePairs.tile1.field.col;
        tile2.row = coordinatePairs.tile2.field.row;
        tile2.col = coordinatePairs.tile2.field.col;

        let newVal1 = tile1.row * 7 + (tile1.col + 1);
        let newVal2 = tile2.row * 7 + (tile2.col + 1);

        this.cellIds = [newVal1, newVal2];
      },

      makeCellsTransparent() {
        this.cellIds.forEach(function (id) {
          document.getElementById(id).style.zIndex = -1;
        });
      },

      resetCellsTransparency() {
        this.cellIds.forEach(function (id) {
          document.getElementById(id).style.zIndex = 0;
        });
      },

      onSwapMatches: function (data) {
        if (this.showInstruction) {
          this.startTimer();
          this.canvasOverlayToggle('none');
          this.showInstruction = false;
        }
        this.showPotentialMoves = false;
        this.progressVal = 100;
        clearInterval(this.timer);
        if (data.matchesLength == 0) {
          this.changeUiAppearance();
          this.startTimer();
          gameObject.turn = PlayerTurn.player2;
          if (this.turnCount % 2 == 0) {
            setTimeout(() => {
              this.handleBotPlay();
            }, 3000);
          }
        }
      },
      onBoardFallDownOver: function () {
        if (this.burstFourActive) {
          gameObject.destroyFourValidation = false;
          this.burstFourActive = false;
          this.playerPowerups = this.playerPowerups.map(pp => {
            if (Object.values(pp).includes('burstFour')) {
              pp.status = POWERUPS_STATUS.USED;
            }
            return pp;
          });
        }
        if (this.swapTwoActive) {
          gameObject.swapTwoValidation = false;
          this.swapTwoActive = false;
          this.playerPowerups = this.playerPowerups.map(pp => {
            if (Object.values(pp).includes('swapTwo')) {
              pp.status = POWERUPS_STATUS.USED;
            }
            return pp;
          });
        }
        if (this.doublePointsActive) {
          let scoreDiff = gameObject.players[0].score - this.room[0].score;
          this.room[0].score = gameObject.players[0].score + scoreDiff;
          gameObject.players[0].score = this.room[0].score;

          let botScoreDiff = gameObject.players[1].score - this.room[1].score;
          this.room[1].score = gameObject.players[1].score + botScoreDiff;
          gameObject.players[1].score = this.room[1].score;

          this.doublePointsActive = false;
          if (this.turnCount % 2 != 0) {
            this.playerPowerups = this.playerPowerups.map(pp => {
              if (Object.values(pp).includes('doublePoints')) {
                pp.status = POWERUPS_STATUS.USED;
              }
              return pp;
            });
          }
        } else {
          this.room[0].score = gameObject.players[0].score;
          this.room[1].score = gameObject.players[1].score;
        }

        if (this.turnCount == this.totalTurns) {
          this.room[1].turnNo++;
          this.gameOver();
          return;
        }
        this.changeUiAppearance();
        this.startTimer();
        if (this.turnCount % 2 == 0) {
          setTimeout(() => {
            this.handleBotPlay();
          }, getRandomNumber(1, 3) * 1000);
        }
      },

      changeUiAppearance() {
        this.turnCount++;
        if (this.turnCount % 2 != 0) {
          this.room[1].turnNo++;
          this.canvasOverlayToggle('none');
          this.turn = 'Your Turn';
          this.scoreBoxToggle = 1;
          this.timerColor = 'bg-info';
          if (this.turnCount < 6) {
            this.resetCellsTransparency();
            gameObject.turn = PlayerTurn.player1;
            this.handlePotentialMoves();
            this.showPotentialMoves = true;
            this.makeCellsTransparent();
          }
        } else {
          this.room[0].turnNo++;
          this.canvasOverlayToggle('block');
          this.turn = "Opponent's Turn";
          this.scoreBoxToggle = 0;
          this.timerColor = 'bg-danger';
          this.showPotentialMoves = false;
        }
        this.progressVal = 100;
        this.timeLeft = 15000;
        this.toggleScoreBox(this.scoreBoxToggle);
        let scoreBox = this.scoreBoxToggle ? 'score-box-blue' : 'score-box-red';
        let animationClass = this.scoreBoxToggle
          ? 'scoreBlue-animation'
          : 'scoreRed-animation';

        let scoreBoxElement = document.getElementById(scoreBox);
        scoreBoxElement.classList.add(animationClass);

        scoreBoxElement.addEventListener(
          'animationend',
          () => {
            scoreBoxElement.classList.remove(animationClass);
          },
          { once: true },
        );
      },

      toggleScoreBox: function (zIndexValue) {
        document.getElementById('score-box-blue').style.zIndex = zIndexValue;
      },
      canvasOverlayToggle: function (display) {
        document.getElementById('canvas-overlay').style.display = display;
      },
      gameOver() {
        clearInterval(this.timer);
        gameObject.disabled = true;
        this.canvasOverlayToggle('block');
        this.showWinner = true;
      },

      startTimer() {
        clearInterval(this.timer);

        this.timer = setInterval(() => {
          if (!this.freezeActive) {
            if (this.timeLeft > 0) {
              this.timeLeft -= 100;
              this.progressVal = (this.timeLeft / (15 * 1000)) * 100;
            } else {
              if (this.turnCount == this.totalTurns) {
                this.room[1].turnNo++;
                this.gameOver();
                return;
              }
              this.changeUiAppearance();
              this.startTimer();
              gameObject.turn = PlayerTurn.player2;
              if (this.turnCount % 2 == 0) {
                setTimeout(() => {
                  this.handleBotPlay();
                }, getRandomNumber(1, 3) * 1000);
              }
            }
          }
        }, 100);
      },
    },

    async mounted() {
      pixiObj = new MegamatchPixi(true);
      await pixiObj.init();

      window.addEventListener('resize', this.onWindowResize);

      gameObject = new Game(
        FirstGameData.firstGameBoard,
        FirstGameData.firstGameNextTiles,
        true,
      );

      gameObject.tutorialGamePlay = true;

      gameObject.stateChanges.callbacks = {};
      if (pixiObj.app.stage) {
        pixiObj.app.stage.addChild(gameObject.container);
      }
      gameObject.stateChanges.on(Events.SWAP_MATCH, data => {
        this.onSwapMatches(data);
      });
      gameObject.stateChanges.on(Events.CHANGES, this.onBoardFallDownOver);

      this.makeCellsTransparent();

      this.startPowerupSelectTimer();
    },
    unmounted() {
      window.removeEventListener('resize', this.onWindowResize);
    },
  };
</script>

<style lang="css">
  @import './index.css';
  @import '../../GameUi/index.css';
</style>
