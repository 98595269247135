<template>
  <div
    v-bind:style="backgroundImage"
    class="align-items-center d-flex flex-column insufficient-main justify-content-center"
  >
    <div
      class="align-items-center insufficient-board-container d-flex flex-column justify-content-start w-75"
    >
      <img :src="coinBag" class="coin-bag" />
      <div class="pb-5 pt-2 text-center">
        <span class="fs-1"
          >Insufficient
          <br />
          funds
          <br
        /></span>
        <hr class="m-2 opacity-100" />
        You do not have enough <br />
        coins to pLay again. <br />
        <br />
        Top up or come back
        <br />
        tomorrow to pLay!
      </div>
    </div>
    <button
      @click="navigateToTopupFunds"
      type="button"
      class="top-up-btn fs-4 text-light mt-5"
    >
      Top up
    </button>
    <button @click="navigateToHome" type="button" class="home-btn fs-4 text-light mt-3">
      Home
    </button>
  </div>
</template>

<script>
  import { staticImages, dynamicBg } from '../../constants/imageUrlPath';

  export default {
    name: 'InsufficientFundsUi',
    data() {
      return {
        coinBag: staticImages.coinBag,
      };
    },
    methods: {
      navigateToTopupFunds() {
        const phone = this.$route.query.phone;
        this.$router.push({ name: 'topupfunds', query: { phone } });
      },
      navigateToHome() {
        const phone = this.$route.query.phone;
        this.$router.push({ name: 'home', query: { phone } });
      },
    },
    computed: {
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
          '--board-background': `url(${staticImages['board2']})`,
        };
      },
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
