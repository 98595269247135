<template>
  <div
    v-bind:style="backgroundImage"
    class="justify-content-center powerup-selection-main"
  >
    <div class="align-items-end d-flex" style="height: 20%">
      <span class="fs-1 text-center text-shadow">SELECT 2<br />POWERUPS</span>
    </div>
    <div class="d-flex row justify-content-center w-75" style="height: 60%">
      <div class="row">
        <div
          class="col align-items-center d-flex flex-column justify-content-center w-50 h-100"
        >
          <img
            :src="powerupSelectionElements['burstFour']"
            class="powerup-img"
            id="burstFour"
            @click="onPowerUpClicked"
          />
          <span class="text-center powerup-text text-shadow">destroy<br />4 pieces</span>
        </div>
        <div
          class="col align-items-center d-flex flex-column justify-content-center w-50 h-100"
        >
          <img
            :src="powerupSelectionElements['potentialMoves']"
            class="powerup-img"
            id="potentialMoves"
            @click="onPowerUpClicked"
          />
          <span class="text-center powerup-text text-shadow"
            >view potential<br />moves</span
          >
        </div>
      </div>
      <div class="row">
        <div
          class="col align-items-center d-flex flex-column justify-content-center w-50 h-100"
        >
          <img
            :src="powerupSelectionElements['doublePoints']"
            class="powerup-img"
            id="doublePoints"
            @click="onPowerUpClicked"
          />
          <span class="text-center powerup-text text-shadow">double<br />points</span>
        </div>
        <div
          class="col align-items-center d-flex flex-column justify-content-center w-50 h-100"
        >
          <img
            :src="powerupSelectionElements['freeze']"
            class="powerup-img"
            id="freeze"
            @click="onPowerUpClicked"
          />
          <span class="text-center powerup-text text-shadow">freeze<br />time</span>
        </div>
      </div>
      <div class="row">
        <div
          class="col align-items-center d-flex flex-column justify-content-center w-50 h-100"
        >
          <img
            :src="powerupSelectionElements['swapTwo']"
            class="powerup-img"
            id="swapTwo"
            @click="onPowerUpClicked"
          />
          <span class="text-center powerup-text text-shadow"
            >swap any<br />two pieces</span
          >
        </div>
        <div
          class="col align-items-center d-flex flex-column justify-content-center w-50 h-100"
        >
          <img
            :src="powerupSelectionElements['reshuffle']"
            class="powerup-img"
            id="reShuffle"
            @click="onPowerUpClicked"
          />
          <span class="text-center powerup-text text-shadow">reshuffle<br />board</span>
        </div>
      </div>
    </div>
    <div
      class="progress-bar-container d-flex flex-column justify-content-center"
      style="height: 15%"
    >
      <ProgressBar
        :progressVal="progressVal"
        labelVal="GAME BEGINS IN..."
        :height="1"
        :color="timerColor"
        style="height: 50%"
      />
    </div>
  </div>
</template>

<script setup>
  import { defineProps } from 'vue';

  defineProps({
    progressVal: {
      type: Number,
    },
    onPowerUpClicked: {
      type: Function,
    },
    powerups: {
      type: Array,
    },
  });
</script>

<script>
  import ProgressBar from '../ProgressBar';
  import { dynamicBg, imageUrls } from '../../constants/imageUrlPath';

  export default {
    name: 'PowerupSelection',
    components: {
      ProgressBar,
    },
    data() {
      return {
        powerupSelectionElements: {
          burstFour: imageUrls.bombBlue,
          potentialMoves: imageUrls.potentialMovesBlue,
          doublePoints: imageUrls.doublePointsBlue,
          freeze: imageUrls.freezeBlue,
          swapTwo: imageUrls.swapTwoBlue,
          reshuffle: imageUrls.reshuffleBlue,
        },
      };
    },
    methods: {},
    computed: {
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
        };
      },
    },
  };
</script>

<style lang="css">
  @import 'index.css';
</style>
