<template>
  <div
    v-bind:style="backgroundImage"
    class="align-items-center d-flex flex-column rules-main"
  >
    <div class="mt-4 text-center text-shadow w-50">
      <h1>RuLes & How to pLay</h1>
      <hr class="m-0 opacity-100" />
    </div>
    <div class="mt-4 text-black w-75">
      <div class="d-flex w-100">
        <p class="align-self-center w-75">
          CoLLect 50 free coins <br />
          everyday. (The coins <br />
          reset everyday at 12:00)
        </p>
        <img :src="clockCoindBag" class="object-fit-contain width-30" />
      </div>

      <div class="d-flex mt-3 w-100">
        <img :src="coinX10" class="object-fit-contain width-30" />
        <p class="align-self-center w-75 text-end">
          Use 10 coins per game <br />
          (with a chance to win <br />
          your opponents coins)
        </p>
      </div>

      <div class="d-flex mt-3 w-100">
        <p class="align-self-center w-75">
          Accumulate coins to <br />
          gain more entries <br />
          into the daily draw <br /><br />
          Battle online players <br />
          & gain as many points <br />
          as possible.
        </p>
        <img :src="youVsOpponent" class="object-fit-contain width-30" />
      </div>

      <div class="d-flex mt-3 w-100">
        <img :src="seconds15Turns8" class="object-fit-contain width-30" />
        <p class="align-self-center w-75 text-end">
          Each player has 8, 15 <br />
          seconds turns to gain as <br />
          many points as possible.
        </p>
      </div>

      <div class="d-flex mt-3 w-100">
        <p class="align-self-center w-75">
          Play on the same <br />
          board as your <br />
          opponent and see <br />
          their real time moves <br /><br />
          1 coins = 1 Entry into <br />
          the daily draw.
        </p>
        <img :src="playSameBoard" class="object-fit-contain width-30" />
      </div>

      <div class="d-flex mt-3 w-100">
        <img :src="playAirtime" class="object-fit-contain width-30" />
        <p class="align-self-center text-end w-75">
          Want to play more <br />
          but out of coins? <br />
          Top up with airtime <br />
          or wait tiLL 12:00
        </p>
      </div>
    </div>
    <button @click="back" class="back-btn fs-4 mb-5 mt-3 text-white w-50">Back</button>
  </div>
</template>

<script>
  import { staticImages } from '../../constants/imageUrlPath';

  export default {
    name: 'LeaderBoardUi',
    methods: {
      back() {
        this.$router.go(-1);
      },
    },
    data() {
      return {
        seconds15Turns8: staticImages.seconds15Turns8,
        clockCoindBag: staticImages.clockCoindBag,
        coinX10: staticImages.coinX10,
        playAirtime: staticImages.playAirtime,
        playSameBoard: staticImages.playSameBoard,
        youVsOpponent: staticImages.youVsOpponent,
      };
    },
    computed: {
      backgroundImage() {
        return {
          '--background-image': `url(${staticImages['bgScreen02']})`,
        };
      },
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
