export const textPrompts = [
    {
      index: 0,
      name: 'chira',
      text: 'hey! someone stole my coins & i need to get them back. please can you help me!',
    },
    {
      index: 1,
      name: 'lionel',
      text: 'hello! welcome to mega match rivals im so happy you are here! chira and i need your help.',
    },
    {
      index: 2,
      name: 'chira',
      text: 'hey! someone stole my coins & i need to get them back. please can you help me! ',
    },
    {
      index: 3,
      name: 'brutis',
      text: 'oh, you want your coins back? well i have an idea...',
    },
    {
      index: 4,
      name: 'chira',
      text: "i knew you'd take them! give them back!",
    },
    {
      index: 5,
      name: 'brutis',
      text: "how about this... let's play a game, if you win, i'll give the coins back but if i win, i get to keep them.",
    },
    {
      index: 6,
      name: 'lionel',
      text: "don't worry chira, we'll help you! let's go get those coins.",
    },
    {
      index: 7,
      name: 'chira',
      text: "please destroy this rude rhino & get my coins back for me! i can't do it on my own.",
    },
    {
      index: 8,
      name: 'lionel',
      text: "you need to match 3 jewels (or more) to gain points, get the most points and win! but it's not that easy, you'll be playing against an opponent! \nyou both get eight turns, 25 seconds each to get as many points as possible whilst playing on the same board!",
    },
    {
      index: 9,
      name: 'chira',
      text: "let's go kick his butt!",
    },
  ];