import { createApp } from "vue";
import App from "./App.vue";
import VueNumber from "vue-number-animation";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import "bootstrap/dist/css/bootstrap.css";
import router from "./router/index";

const app = createApp(App);

app.use(router);
app.use(VueNumber);
app.use(Toast);

app.config.globalProperties.$DYNAMIC_BG = process.env.VUE_APP_DYNAMIC_BG;

// Add a global mixin to set the #app element's height
app.mixin({
  mounted() {
    this.setAppHeight();
    window.addEventListener("resize", this.setAppHeight);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setAppHeight);
  },
  methods: {
    setAppHeight() {
      const appDiv = document.getElementById("app");
      if (appDiv) {
        appDiv.style.height = window.innerHeight + "px";
      }
    },
  },
});

app.config.warnHandler = function () {
  return null
}

app.mount("#app");
