<template>
  <div
    :class="['progress progressGame', direction]"
    :style="{
      height: `100%`,
      background: background,
      borderImage: `url(${staticImages['gameBeginsLoadingBar']}) 38 round`,
      borderRadius: '13px',
      borderImageWidth: '12px',
    }"
  >
    <div
      :class="['progress-bar', color]"
      role="progressbar"
      :style="{ width: `${progressVal}%` }"
      :aria-valuenow="progressVal"
      aria-valuemin="0"
      aria-valuemax="100"
    />
    <span class="label">{{ labelVal }}</span>
  </div>
</template>

<script setup>
  import { staticImages } from '../../constants/imageUrlPath';
  import { defineProps } from 'vue';

  defineProps({
    progressVal: Number,
    direction: String,
    labelVal: String,
    height: Number,
    color: String,
    background: String,
  });
</script>

<script>
  export default {
    name: 'ProgressBar',
    computed: {
      backgroundImages() {
        console.log('url :>> ', staticImages['gameBeginsLoadingBar']);
        return {
          '--game-begins-loading-bar-border': `url(${staticImages['gameBeginsLoadingBar']})`,
        };
      },
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
