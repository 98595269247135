<template>
  <div v-bind:style="backgroundImage" class="justify-content-evenly signup-main">
    <div class="h-25 w-75">
      <img :src="signUpScreenElements['logo']" alt="" srcset="" class="h-100 w-100" />
    </div>
    <div :class="[textColor, 'fs-5', 'pe-2', 'ps-2', 'text-center']">
      stand a chance to win airtime & <br />
      other great prizes. Enter your ceLL <br />
      number below to start playing.
    </div>
    <div class="d-flex flex-column h-25 w-75">
      <input
        v-model="inputValue"
        type="tel"
        id="phoneNumber"
        @input="checkInput"
        placeholder="Enter your number"
        class="border-0 fs-3 input-number rounded-3 text-center"
        :style="{ height: '35%' }"
      />
      <button
        @click="navigateToHomePage"
        :disabled="isButtonDisabled"
        type="button"
        :class="`${!isButtonDisabled ? `btn ` : `btn-disabled `}fs-3 mt-3 text-light`"
      >
        start playing
      </button>
    </div>
    <SpinnerUi v-if="showSpinner" />
  </div>
</template>

<script>
  import SpinnerUi from '../SpinnerUi';
  import { createUser } from '../../handlers/apis';
  import { staticImages, dynamicBg } from '../../constants/imageUrlPath';

  export default {
    name: 'SignUpScreenUi',
    components: {
      SpinnerUi,
    },
    data() {
      return {
        inputValue: '',
        isButtonDisabled: true,
        showSpinner: false,
        textColor: 'text-black',
        signUpScreenElements: staticImages,
      };
    },
    methods: {
      checkInput() {
        this.isButtonDisabled = this.inputValue.trim() === '';
      },
      async navigateToHomePage() {
        this.showSpinner = true;
        try {
          const phone = this.inputValue.trim();
          const user = await createUser({
            phoneNumber: phone,
            online: false,
          });
          localStorage.setItem('userData', JSON.stringify(user));
          this.$router.push({ name: 'home', query: { phone } });
        } catch (error) {
          console.log(error.message);
        }
      },
    },
    computed: {
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
          '--btn-background': `url(${staticImages['purpleButton']})`,
        };
      },
    },
    mounted() {
      if (this.$DYNAMIC_BG == 'nigeria1' || this.$DYNAMIC_BG == 'nigeria3')
        this.textColor = 'text-white';
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
