<template>
  <div
    v-bind:style="backgroundImage"
    class="align-items-center d-flex flex-column collect-coins-main justify-content-center"
  >
    <div
      class="align-items-center insufficient-board-container d-flex flex-column justify-content-start w-75"
    >
      <img :src="singleCoin" class="w-25" style="margin-top: -1rem" />
      <div class="pb-5 pt-2 text-center">
        <span class="fs-5"> You have earned </span> <br />
        <span class="fs-1"> 50 Coins </span>
        <hr class="m-2 opacity-100" />
        Come back tomorrow <br />
        to earn 50 more
      </div>
    </div>
    <button
      @click="navigateToConfirmPage"
      type="button"
      class="collect-coins-btn fs-4 text-light mt-5 w-75"
    >
      CoLLect coins
    </button>
  </div>
</template>

<script>
  import { staticImages, dynamicBg } from '../../constants/imageUrlPath';

  export default {
    name: 'FirstLoginCoinCollectUi',
    data() {
      return {
        singleCoin: staticImages.singleCoin,
      };
    },
    methods: {
      navigateToConfirmPage() {
        const phone = this.$route.query.phone;
        const data = JSON.parse(localStorage.getItem('userData'));
        if (data && data.userName) {
          this.$router.push({ name: 'gameconfirm', query: { phone } });
        } else {
          this.$router.push({ name: 'intro', query: { phone } });
        }
      },
    },
    computed: {
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
          '--board-background': `url(${staticImages['board1']})`,
        };
      },
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
