const BASE_URL = window.location.origin;

const assetFolderPath = `${BASE_URL}/assets`;
const imageAssetPath = `${assetFolderPath}/images`;
const animationAssetPath = `${assetFolderPath}/animations`;
const powerupsAssetPath = `${imageAssetPath}/powerups`;
const tutorialAssetPath = `${assetFolderPath}/Tutorial`;
const playerIconsAssetPath = `${assetFolderPath}/playerIcons`;
const gameScreenElementsAssetPath = `${assetFolderPath}/gameScreenElements`;
const startScreenElementsAssetPath = `${assetFolderPath}/startScreenElements`;

export const imageFileNames = [
  'bg.jpg',
  'blue-gem.png',
  'blue-player-turn.png',
  'field-selected.jpg',
  'field1.jpg',
  'field2.jpg',
  'green-gem.png',
  'gold-gem.png',
  'purple-gem.png',
  'red-gem.png',
  'red-player-turn.png',
  'bg-screen-02.jpg',
  'bg-screen-03.jpg',
  '15-seconds-8-turns.png',
  'clock-and-coin-bag.png',
  'coins-x10.png',
  'how-to-play-airtime.png',
  'play-on-the-same-board.png',
  'you-vs-opponent.png',
  'coin-bag.png',
];

export const powerupsFileName = [
  'bomb-blue.png',
  'double-points-blue.png',
  'switch-blue.png',
  'bomb-red.png',
  'double-points-red.png',
  'switch-red.png',
  'cheetah-eyes-blue.png',
  'freeze-blue.png',
  'tornado-blue.png',
  'cheetah-eyes-red.png',
  'freeze-red.png',
  'tornado-red.png',
];

export const tutorialFileNames = [
  'cheata-02.png',
  'lion-02.png',
  'lion-03.png',
  'rhino-cheta-lion.png',
  'text-box-1.png',
  'text-box-2.png',
  'text-box-3.png',
  'text-box-4.png',
  'text-box-5.png',
  'text-box-6.png',
  'text-box-7.png',
  'text-box-8.png',
  'text-box-9.png',
  'text-box-10.png',
  'text-box-11.png',
  'text-box-12.png',
];

export const playerIconsFileNames = [
  'armadillo-blue-icon.png',
  'crocodile-blue-icon.png',
  'monkey-blue-icon.png',
  'armadillo-red-icon.png',
  'crocodile-red-icon.png',
  'monkey-red-icon.png',
  'bunny-blue-icon.png',
  'elephant-blue-icon.png',
  'rhino-blue-icon.png',
  'bunny-red-icon.png',
  'elephant-red-icon.png',
  'rhino-red-icon.png',
  'cow-blue-icon.png',
  'fish-blue-icon.png',
  'snake-blue-icon.png',
  'cow-red-icon.png',
  'fish-red-icon.png',
  'snake-red-icon.png',
];

export const gameScreenElementsFileNames = [
  'logo.png',
  'player-moves-bg.png',
  'blue-player-turn.png',
  'power-boarder.png',
  'red-player-turn.png',
  'exit-icon.png',
  'settings-icon.png',
  'game-begins-loading-bar.png',
  'timer-boarder.png',
  'gameboard.png',
  'nigeria_background_1.jpg',
  'nigeria_background_2.jpg',
  'nigeria_background_3.jpg',
];

export const startScreenElementsFileNames = [
  'board-01.png',
  'board-02.png',
  'board-03.png',
  'loading-screen-logo.png',
  'coins-bg.png',
  'logo.png',
  'green-button-big.png',
  'purple-button-big.png',
  'green-circle-button.png',
  'purple-button.png',
  'home-screen.jpg',
  'purple-button-small.png',
  'loading-screen-loading-bar.png',
  'single-coin.png',
];

export const animationFileNames = [
  'SpriteSheet-SingleExplosions/SingleExplosions.json',
  'Searching-ForCharacter-SpriteSheet/search.json',
  'DrawAnimationSpriteSheet/Draw-Animation.json',
  'WinnerSpritesheet/winner-animation.json',
  'LoserSpritesheet/loser-animation.json',
  'Powerup-Animations/freezeV2/freeze.json',
  'Powerup-Animations/reshuffleV2/reshuffle.json',
  'Powerup-Animations/search/search-powerup/search-powerup.json',
  'Powerup-Animations/2x-points/2x-points.json',
  'Powerup-Animations/swap-2/swap-2.json',
  'Powerup-Animations/bomb/bomb.json',
];

export const assetsPaths = [
  ...imageFileNames.map(imageFilename => `${imageAssetPath}/${imageFilename}`),
];

export const animationPaths = [
  ...animationFileNames.map(
    animationFileName => `${animationAssetPath}/${animationFileName}`,
  ),
];

export const staticImagePaths = [
  ...powerupsFileName.map(path => `${powerupsAssetPath}/${path}`),
  ...tutorialFileNames.map(path => `${tutorialAssetPath}/${path}`),
  ...playerIconsFileNames.map(path => `${playerIconsAssetPath}/${path}`),
  ...gameScreenElementsFileNames.map(path => `${gameScreenElementsAssetPath}/${path}`),
  ...startScreenElementsFileNames.map(path => `${startScreenElementsAssetPath}/${path}`),
];
