<template>
  <div v-bind:style="backgroundImage" class="justify-content-between home-main">
    <div class="align-items-center d-flex flex-column h-50 w-100">
      <div class="align-self-end me-3 mt-3">
        <button @click="navigateToRules" class="rules-btn text-white">?</button>
      </div>

      <div class="h-50 w-75">
        <img :src="homeScreenElements['logo']" alt="logo" class="h-100 w-100" />
      </div>
      <div
        className="align-items-center coins-container d-flex justify-content-center mt-5 w-50"
      >
        <div className="">
          <span className="fs-4">50 coins</span>
        </div>
        <div className="p-2 w-25">
          <img :src="homeScreenElements['singleCoin']" className="w-100" />
        </div>
      </div>
    </div>

    <div className="align-items-center d-flex flex-column h-25 w-100">
      <button
        @click="navigateToCollectCoins"
        type="button"
        class="playBtn fs-3 text-light"
      >
        PLay
      </button>
      <button
        @click="navigateToLeaderBoard"
        type="button"
        class="fs-4 text-light winnerBtn"
      >
        Winners
      </button>
    </div>
  </div>
</template>

<script>
  import { staticImages } from '../../constants/imageUrlPath';

  export default {
    name: 'HomeScreenUi',
    data() {
      return {
        homeScreenElements: {
          logo: staticImages.logo,
          singleCoin: staticImages.singleCoin,
        },
      };
    },
    methods: {
      navigateToCollectCoins() {
        const phone = this.$route.query.phone;
        this.$router.push({ name: 'collectcoins', query: { phone } });
        
      },
      navigateToLeaderBoard() {
        this.$router.push({ name: 'leaderboard' });
      },
      navigateToRules() {
        this.$router.push({ name: 'rules' });
      },
    },
    computed: {
      backgroundImage() {
        return {
          '--home-main-background': `url(${staticImages['homeScreen']})`,
          '--coins-container-background': `url(${staticImages['coinsBg']})`,
          '--playBtn-background': `url(${staticImages['purpleButtonBig']})`,
          '--winnerBtn-background': `url(${staticImages['greenButtonBig']})`,
        };
      },
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
