<template>
  <div v-bind:style="backgroundImage" class="justify-content-center confirm-main">
    <div
      class="align-items-center board-container d-flex flex-column justify-content-start w-75"
    >
      <img
        :src="gameConfirmScreenElements['singleCoin']"
        class="w-25"
        style="margin-top: -1rem"
      />
      <div class="p-4 pt-2 text-center">
        You are about to enter
        <br />
        into an onLine game.
        <br />
        <hr class="m-2 opacity-100" />
        PLease cLick 'confirm' <br />
        to begin searching for <br />
        an opponent <br />
        <hr class="m-2 opacity-0" />
        This game wiLL cost you
        <br />
        <span class="fs-2">100 coins</span>
      </div>
    </div>
    <button
      @click="navigateToOpponentSearch"
      type="button"
      class="confirmBtn fs-4 text-light mt-5"
    >
      Confirm
    </button>
    <button @click="back" type="button" class="backBtn fs-4 text-light mt-3">
      Back
    </button>
  </div>
</template>

<script>
  import { staticImages, dynamicBg } from '../../constants/imageUrlPath';

  export default {
    name: 'GameConfirmationScreenUi',
    methods: {
      navigateToOpponentSearch() {
        const phone = this.$route.query.phone;
        this.$router.push({ name: 'game', query: { phone } });
      },
      back() {
        this.$router.go(-1);
      },
    },
    data() {
      return {
        gameConfirmScreenElements: {
          singleCoin: staticImages.singleCoin,
        },
      };
    },
    computed: {
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
          '--board-backBtn-background': `url(${staticImages['board2']})`,
          '--confirmBtn-background': `url(${staticImages['purpleButtonBig']})`,
          '--backBtn-background': `url(${staticImages['greenButtonBig']})`,
        };
      },
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
