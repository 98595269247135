<template>
    <div class="align-items-center d-flex h-100 justify-content-center position-absolute w-100">
        <div class="h-100 w-100 position-absolute bg-dark opacity-50"/>
        <div class="spinner-border text-primary z-1" role="status" style="width: 3rem; height: 3rem;">
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpinnerUi',
}
</script>