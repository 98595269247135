<template>
  <div
    v-bind:style="backgroundImage"
    class="align-items-center d-flex flex-column leaderboard-main"
  >
    <div class="align-self-end me-3 mt-3">
      <button @click="navigateRules" class="rules-btn text-white">?</button>
    </div>
    <div class="mt-3 text-shadow">
      <h1>Leaderboard</h1>
      <hr class="m-0 opacity-100" />
    </div>
    <table class="table leaderboard-table mt-4">
      <thead>
        <tr class="head-row text-shadow">
          <th class="border-0 text-white name-block ps-3">Name</th>
          <th class="border-0 text-end text-white">entries</th>
          <th class="border-0 text-end text-white prize-block pe-3">Prize</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in tableData" :key="index">
          <td class="ps-3">{{ data.name }}</td>
          <td class="text-end">{{ data.entries }}</td>
          <td class="text-end pe-3">{{ data.prize }}</td>
        </tr>
      </tbody>
    </table>
    <button @click="back" class="back-btn fs-4 mt-5 text-white w-50">Back</button>
  </div>
</template>

<script>
  import { staticImages } from '../../constants/imageUrlPath';
  import { leaderboardData } from '../../constants/dummyData';

  export default {
    name: 'LeaderBoardUi',
    methods: {
      navigateRules() {
        this.$router.push({ name: 'rules' });
      },
      back() {
        this.$router.go(-1);
      },
    },
    data() {
      return {
        tableData: leaderboardData,
      };
    },
    computed: {
      backgroundImage() {
        return {
          '--background-image': `url(${staticImages['bgScreen03']})`,
        };
      },
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
