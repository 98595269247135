<template>
  <div class="launch-main">
    <div class="h-25 mt-5 w-75">
      <img src="/assets/startScreenElements/logo.png" alt="logo" class="h-100 w-100" />
    </div>
  </div>

  <img
    v-for="(image, index) in imgSrc"
    :key="index"
    :src="image"
    style="display: none"
    @load="onImageLoad"
  />
</template>

<script>
  import { dynamicBg, staticImages } from '../../constants/imageUrlPath';

  export default {
    name: 'LaunchScreenUi',
    data() {
      return {
        loadedImages: 0,
        imgSrc: [dynamicBg['0'], staticImages['loadingScreenLogo']],
      };
    },
    methods: {
      onImageLoad() {
        this.loadedImages++;
        if (this.loadedImages === this.imgSrc.length) {
          setTimeout(() => {
            this.$router.push({ name: 'loading' });
          }, 1000);
        }
      },
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
