<template>
  <div v-bind:style="backgroundImage" class="justify-content-evenly search-opponent-main">
    <div className="align-items-end d-flex h-25 top-text">
      <span class="fs-10 text-center text-with-shadow"
        >searching for <br />
        opponent</span
      >
    </div>

    <div className="align-items-center d-flex h-50 row">
      <div
        className="align-items-center col d-flex flex-column justify-content-center w-50 px-5"
      >
        <div
          id="container-for-image"
          className="text-center align-items-center justify-content-center"
        ></div>
        <span className="fs-2 text-with-shadow">YOU</span>
      </div>
      <div
        className="align-items-center col d-flex flex-column justify-content-center w-50 px-5"
      >
        <SearchingOpponentsAnimation />
        <span className="fs-2 text-with-shadow">OPPONENT</span>
      </div>
    </div>

    <div className="d-flex h-25 justify-content-center p-4">
      <img :src="logo" className="h-100 w-100" />
    </div>
  </div>
</template>

<script>
  import { Config } from '@/game/Config';
  import SearchingOpponentsAnimation from '../../components/SearchAnimationUi';
  import { PlayerIconBlue, dynamicBg, staticImages } from '../../constants/imageUrlPath';
  import * as PIXI from 'pixi.js';

  /** @type PIXI.Application */
  let app, sprite, configInstance;
  export default {
    name: 'OpponentSearchingUi',
    components: {
      SearchingOpponentsAnimation,
    },
    data() {
      return {
        playerIconBlue: PlayerIconBlue,
        avatar: '',
        logo: staticImages.logo,
      };
    },
    mounted() {
      const data = JSON.parse(localStorage.getItem('userData'));
      this.avatar = data?.avatar;
      configInstance = new Config(true);
      // Initialize PIXI application for the image container
      app = new PIXI.Application({
        backgroundAlpha: 0,
      });
      app.view.width = configInstance.uiConstants.width / 3;
      app.view.height = configInstance.uiConstants.height / 3;
      document.getElementById('container-for-image').appendChild(app.view);

      // Load and display an image using PIXI.Sprite
      const texture = PIXI.Texture.from(
        this.playerIconBlue[this.avatar] || this.playerIconBlue['armadillo'],
      );
      sprite = new PIXI.Sprite(texture);
      sprite.width = configInstance.uiConstants.width / 3;
      sprite.height = configInstance.uiConstants.height / 3;
      app.stage.addChild(sprite);

      window.addEventListener('resize', this.onWindowResize);
    },
    computed: {
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
        };
      },
    },
    unmounted() {
      window.removeEventListener('resize', this.onWindowResize);
      if (app) {
        app.destroy();
      }
    },
    methods: {
      onWindowResize() {
        configInstance = new Config(true);
        app.view.width = configInstance.uiConstants.width / 3;
        app.view.height = configInstance.uiConstants.height / 3;
        sprite.width = configInstance.uiConstants.width / 3;
        sprite.height = configInstance.uiConstants.height / 3;
      },
    },
  };
</script>

<style lang="css">
  @import '../../App.css';
  @import './index.css';
</style>
