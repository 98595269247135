<template>
  <div
    v-bind:style="backgroundImages"
    class="container d-flex flex-column justify-content-center pb-1 progress-container pt-1"
  >
    <!-- <img class="w-100" src="/assets/gameScreenElements/timer-boarder.png" alt="" /> -->
    <ProgressBar
      :progressVal="progressVal"
      :labelVal="turn"
      :height="3"
      :color="timerColor"
      :background="'#463828'"
    />
  </div>
</template>

<script setup>
  import { defineProps } from 'vue';

  defineProps({
    turn: {
      type: String,
    },
    timerColor: {
      type: String,
    },
    progressVal: {
      type: Number,
    },
  });
</script>

<script>
  import ProgressBar from '../ProgressBar';
  import { staticImages } from '../../constants/imageUrlPath';

  export default {
    name: 'NavigationUi',
    data() {},
    components: {
      ProgressBar,
    },
    computed: {
      backgroundImages() {
        return {
          '--progress-container-border': `url(${staticImages['timerBorder']})`,
        };
      },
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
