const BASE_URL = window.location.origin;

export const imageUrls = {
  doublePointsBlue: `${BASE_URL}/assets/images/powerups/double-points-blue.png`,
  reshuffleBlue: `${BASE_URL}/assets/images/powerups/tornado-blue.png`,
  freezeBlue: `${BASE_URL}/assets/images/powerups/freeze-blue.png`,
  swapTwoBlue: `${BASE_URL}/assets/images/powerups/switch-blue.png`,
  doublePointsRed: `${BASE_URL}/assets/images/powerups/double-points-red.png`,
  reshuffleRed: `${BASE_URL}/assets/images/powerups/tornado-red.png`,
  freezeRed: `${BASE_URL}/assets/images/powerups/freeze-red.png`,
  swapTwoRed: `${BASE_URL}/assets/images/powerups/switch-red.png`,
  bombBlue: `${BASE_URL}/assets/images/powerups/bomb-blue.png`,
  bombRed: `${BASE_URL}/assets/images/powerups/bomb-red.png`,
  potentialMovesBlue: `${BASE_URL}/assets/images/powerups/cheetah-eyes-blue.png`,
  potentialMovesRed: `${BASE_URL}/assets/images/powerups/cheetah-eyes-red.png`,
  burstFourBlue: `${BASE_URL}/assets/images/powerups/bomb-blue.png`,
  burstFourRed: `${BASE_URL}/assets/images/powerups/bomb-red.png`,
  reShuffleBlue: `${BASE_URL}/assets/images/powerups/tornado-blue.png`,
  reShuffleRed: `${BASE_URL}/assets/images/powerups/tornado-red.png`,
};

export const PlayerIconBlue = {
  armadillo: `${BASE_URL}/assets/playerIcons/armadillo-blue-icon.png`,
  bunny: `${BASE_URL}/assets/playerIcons/bunny-blue-icon.png`,
  cow: `${BASE_URL}/assets/playerIcons/cow-blue-icon.png`,
  crocodile: `${BASE_URL}/assets/playerIcons/crocodile-blue-icon.png`,
  elephant: `${BASE_URL}/assets/playerIcons/elephant-blue-icon.png`,
  fish: `${BASE_URL}/assets/playerIcons/fish-blue-icon.png`,
  monkey: `${BASE_URL}/assets/playerIcons/monkey-blue-icon.png`,
  snake: `${BASE_URL}/assets/playerIcons/snake-blue-icon.png`,
};

export const PlayerIconRed = {
  armadillo: `${BASE_URL}/assets/playerIcons/armadillo-red-icon.png`,
  bunny: `${BASE_URL}/assets/playerIcons/bunny-red-icon.png`,
  cow: `${BASE_URL}/assets/playerIcons/cow-red-icon.png`,
  crocodile: `${BASE_URL}/assets/playerIcons/crocodile-red-icon.png`,
  elephant: `${BASE_URL}/assets/playerIcons/elephant-red-icon.png`,
  fish: `${BASE_URL}/assets/playerIcons/fish-red-icon.png`,
  monkey: `${BASE_URL}/assets/playerIcons/monkey-red-icon.png`,
  snake: `${BASE_URL}/assets/playerIcons/snake-red-icon.png`,
};

export const dynamicBg = {
  0: `${BASE_URL}/assets/images/bg.jpg`,
  nigeria1: `${BASE_URL}/assets/gameScreenElements/nigeria_background_1.jpg`,
  nigeria2: `${BASE_URL}/assets/gameScreenElements/nigeria_background_2.jpg`,
  nigeria3: `${BASE_URL}/assets/gameScreenElements/nigeria_background_3.jpg`,
};

export const textPrompts = [
  `${BASE_URL}/assets/Tutorial/text-box-2.png`,
  `${BASE_URL}/assets/Tutorial/text-box-1.png`,
  `${BASE_URL}/assets/Tutorial/text-box-2.png`,
  `${BASE_URL}/assets/Tutorial/text-box-3.png`,
  `${BASE_URL}/assets/Tutorial/text-box-4.png`,
  `${BASE_URL}/assets/Tutorial/text-box-5.png`,
  `${BASE_URL}/assets/Tutorial/text-box-6.png`,
  `${BASE_URL}/assets/Tutorial/text-box-7.png`,
  `${BASE_URL}/assets/Tutorial/text-box-8.png`,
  `${BASE_URL}/assets/Tutorial/text-box-9.png`,
];

export const staticImages = {
  logo: `${BASE_URL}/assets/startScreenElements/logo.png`,
  singleCoin: `${BASE_URL}/assets/startScreenElements/single-coin.png`,
  rhinoChetaLion: `${BASE_URL}/assets/Tutorial/rhino-cheta-lion.png`,
  textBox11: `${BASE_URL}/assets/Tutorial/text-box-11.png`,
  lion2: `${BASE_URL}/assets/Tutorial/lion-02.png`,
  board1: `${BASE_URL}/assets/startScreenElements/board-01.png`,
  board2: `${BASE_URL}/assets/startScreenElements/board-02.png`,
  board3: `${BASE_URL}/assets/startScreenElements/board-03.png`,
  purpleButtonBig: `${BASE_URL}/assets/startScreenElements/purple-button-big.png`,
  greenButtonBig: `${BASE_URL}/assets/startScreenElements/green-button-big.png`,
  purpleButton: `${BASE_URL}/assets/startScreenElements/purple-button.png`,
  homeScreen: `${BASE_URL}/assets/startScreenElements/home-screen.jpg`,
  greenCircleButton: `${BASE_URL}/assets/startScreenElements/green-circle-button.png`,
  coinsBg: `${BASE_URL}/assets/startScreenElements/coins-bg.png`,
  bluePlayerTurn: `${BASE_URL}/assets/gameScreenElements/blue-player-turn.png`,
  redPLayerTurn: `${BASE_URL}/assets/gameScreenElements/red-player-turn.png`,
  powerupContainer: `${BASE_URL}/assets/gameScreenElements/power-boarder.png`,
  gameBoard: `${BASE_URL}/assets/gameScreenElements/gameboard.png`,
  timerBorder: `${BASE_URL}/assets/gameScreenElements/timer-boarder.png`,
  gameBeginsLoadingBar: `${BASE_URL}/assets/gameScreenElements/game-begins-loading-bar.png`,
  lion03: `${BASE_URL}/assets/Tutorial/lion-03.png`,
  textBox10: `${BASE_URL}/assets/Tutorial/text-box-10.png`,
  loadingScreenLogo: `${BASE_URL}/assets/startScreenElements/loading-screen-logo.png`,
  bgScreen02: `${BASE_URL}/assets/images/bg-screen-02.jpg`,
  bgScreen03: `${BASE_URL}/assets/images/bg-screen-03.jpg`,
  seconds15Turns8: `${BASE_URL}/assets/images/15-seconds-8-turns.png`,
  clockCoindBag: `${BASE_URL}/assets/images/clock-and-coin-bag.png`,
  coinX10: `${BASE_URL}/assets/images/coins-x10.png`,
  playAirtime: `${BASE_URL}/assets/images/how-to-play-airtime.png`,
  playSameBoard: `${BASE_URL}/assets/images/play-on-the-same-board.png`,
  youVsOpponent: `${BASE_URL}/assets/images/you-vs-opponent.png`,
  coinBag: `${BASE_URL}/assets/images/coin-bag.png`,
};
