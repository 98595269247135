import axios from 'axios';

const BASE_URL = process.env.VUE_APP_BACKEND_URL;

export const createUser = async body => {
  return (await axios.post(`${BASE_URL}/user`, body)).data;
};

export const getUserByPhoneNumber = async phone => {
  return (await axios.get(`${BASE_URL}/user/${phone}`)).data;
};
